var $ = jQuery.noConflict();
var md = new MobileDetect(window.navigator.userAgent);
var interleaveOffset = 0.5;
var frameAnimated = false;

// var rellax = new Rellax('.rellax');


function initGMap() {
	map = new google.maps.Map(document.getElementById('map'), {
		center: {lat: 52.23992, lng: 21.028869},
		zoom: 17
	});
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(52.23992, 21.028869),
		icon: 'assets/images/location/logo-google-map.svg',
		map: map
	});
}

function initProject() {
	swiperProject = new Swiper('.project-swiper', {
		loop: true,
		speed: 1200,
		watchSlidesProgress: true,
		autoplay: {
			disableOnInteraction: true,
			delay: 5000
		},
		on: {
			progress: function (progress) {
				var swiper = this;
				for (var i = 0; i < swiper.slides.length; i++) {
					var slideProgress = swiper.slides[i].progress;
					var innerOffset = swiper.width * interleaveOffset;
					var innerTranslate = slideProgress * innerOffset;
					swiper.slides[i].querySelector(".slide-inner").style.transform =
						"translate3d(" + innerTranslate + "px, 0, 0)";
				}
			},
			touchStart: function () {
				var swiper = this;
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = "";
				}
			},
			setTransition: function (speed) {
				var swiper = this;
				for (var i = 0; i < swiper.slides.length; i++) {
					swiper.slides[i].style.transition = speed + "ms";
					swiper.slides[i].querySelector(".slide-inner").style.transition =
						speed + "ms";
				}
			}
		}
	});
}

function initLocation() {
	swiperLocation = new Swiper('.location-swiper', {
		loop: true,
		speed: 600,
		watchSlidesProgress: true,
		autoplay: {
			disableOnInteraction: true,
			delay: 8000
		},

	});

	$(document).on('click', '.location-next-slide', function () {
		swiperLocation.slideNext();
	});

	$('.map-bt-amenities').click(function () {
		$('.krakow-map').fadeOut();
		$('.map-communication').fadeOut(300, function () {
			$('.map-amenities').show();
		});

		$('.map-bt').removeClass('active');
		$(this).addClass('active');
	});
	$('.map-bt-communication').click(function () {
		$('.krakow-map').fadeIn();

		$('.map-amenities').fadeOut(300, function () {
			$('.map-communication').show();
		});

		$('.map-bt').removeClass('active');
		$(this).addClass('active');
	});
}

function initInvestor() {
	swiperInvestor = new Swiper('.investor-swiper', {
		loop: true,
		speed: 1200,
		autoplay: {
			disableOnInteraction: true,
			delay: 4000
		},

	});

	$(document).on('click', '.investor-next-slide', function () {
		swiperInvestor.slideNext();
	});
	$(document).on('click', '.investor-prev-slide', function () {
		swiperInvestor.slidePrev();
	});
}

function initLocation2() {
	swiperLocation2 = new Swiper('.location-swiper', {
		slidesPerView: 2,
		spaceBetween: 0,
		loop: true,
		simulateTouch: false,
		autoplay: {
			disableOnInteraction: false,
			delay: 4000
		},
		navigation: {
			nextEl: '.location-swiper .swiper-button-next',
			prevEl: '.location-swiper .swiper-button-prev',
		},
		breakpointsInverse: true,
		breakpoints: {
			1024: {
				loop: false,
				navigation: false,
				slidesPerView: 4,
				autoplay: false,
			},
		}
	});


}


function initMainMenu() {

	// Smooth scrolling using jQuery easing
	$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: (target.offset().top - $('header').height() - 20)
				}, 1000, "easeInOutExpo");
				$(".navbar-toggler").toggleClass('is-active');
				return false;
			}
		}
	});

	$(".navbar-toggler").click(function () {
		$(this).toggleClass('is-active');
	});

	$('.js-scroll-trigger').click(function () {
		$('.navbar-collapse').collapse('hide');
	});

	$('body').scrollspy({
		target: '#main-menu',
		offset: $('header').height() + 5
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('header').addClass("sticky-header");
		} else {
			$('header').removeClass("sticky-header");
		}
	});
}


function initAnimations() {

	AOS.init({
		disable: 'mobile',
		duration: 800,
		offset: 300
	});
	if (!md.mobile()) {
		luxy.init();
	}

	if ($('.in-view').length > 0) {
		enterView({
			selector: '.in-view',
			offset: 0.1,
			enter: function (element) {
				handleInView(element);
			},
			exit: function (element) {
				handleOutView(element);
			},
		});
	}
	if ($('.in-view-middle').length > 0) {
		enterView({
			selector: '.in-view-middle',
			offset: 0.5,
			enter: function (element) {
				handleInView(element);
			},
			exit: function (element) {
				handleOutView(element);
			},
		});
	}
}

function handleInView(element) {
	$(element).addClass('is-in-view-first is-in-view');
}

function handleOutView(element) {

	$(element).removeClass('is-in-view');
}


function initOffices() {
	$('.frame-bar').click(function () {
		$('.frame').toggleClass('open');
	});
	$('.tenant-item:not(.tenant-1)').hide();
	$('.tenant-item.tenant-1').fadeIn();

	$('.floor-menu li').click(function () {
		$('.floor-menu li').removeClass('active');
		$(this).addClass("active");

		var t = $(this).data('t');
		$('.tenant-item').hide();
		$('.tenant-' + t)
			.css("display", "flex")
			.hide()
			.fadeIn();
	});

	if ($('.frame').length > 0) {
		enterView({
			selector: '.frame',
			offset: 0.5,
			enter: function (element) {
				if (!frameAnimated) {
					$('.frame-bar').click();
					setTimeout(function () {
						$('.frame-bar').click();
					}, 2000);
				}
			},
		});
	}
}

$(document).ready(function () {

	initMainMenu();
	initLocation();
	initInvestor();
	initProject();
	initAnimations();
	initOffices();
});


